<template>
  <div>
    <el-card>
      <div class="header" v-if="userInfo == null">
        <el-form :inline="true" :model="queryForm" class="demo-form-inline">
          <el-form-item label="用户ID">
            <el-input
              v-model="queryForm.userId"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="称呼">
            <el-input
              v-model="queryForm.fullname"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input v-model="queryForm.phone" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="处理状态">
            <el-select
              v-model="queryForm.status"
              clearable
              placeholder="请选择"
            >
              <el-option label="未处理" value="0"></el-option>
              <el-option label="已处理" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getListData">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div style="margin-bottom: 20px;" v-if="userInfo != null">
        <el-descriptions
          title="用户信息"
          direction="vertical"
          :column="4"
          size="medium"
          border
        >
          <el-descriptions-item label="姓名">{{
            userInfo.fullname
          }}</el-descriptions-item>
          <el-descriptions-item label="性别">
            <el-tag size="small" v-if="userInfo.sex == 1">男</el-tag>
            <el-tag size="small" v-else-if="userInfo.sex == 2">女</el-tag>
            <el-tag size="small" v-else>保密</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="手机号">{{
            userInfo.phone
          }}</el-descriptions-item>
          <el-descriptions-item label="归属地">{{
            userInfo.phoneAddress
          }}</el-descriptions-item>
          <el-descriptions-item label="意向度">
            <el-rate v-model="userInfo.userRating" disabled></el-rate>
          </el-descriptions-item>
          <el-descriptions-item label="身份证号">{{
            userInfo.idNumber
          }}</el-descriptions-item>
          <el-descriptions-item label="身份证地址">{{
            userInfo.idAddress
          }}</el-descriptions-item>
          <el-descriptions-item label="生日">{{
            userInfo.birthday
          }}</el-descriptions-item>
          <el-descriptions-item label="邮箱">{{
            userInfo.email
          }}</el-descriptions-item>
          <el-descriptions-item label="QQ">{{
            userInfo.qq
          }}</el-descriptions-item>
          <el-descriptions-item label="微信">{{
            userInfo.wechat
          }}</el-descriptions-item>
          <el-descriptions-item label="是否已加微信">
            <el-tag
              size="small"
              type="success"
              v-if="userInfo.isAddWechat == true"
              >已添加</el-tag
            >
            <el-tag size="small" type="info" v-else>未添加</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="备注">
            {{ userInfo.remark }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <div
        class="tool"
        style="background: #fafafa;display: flex;padding: 10px;border: 1px solid #ebeef5;border-bottom: 0;"
      >
        <div></div>
        <div style="flex: 1;text-align: right;">
          <el-button size="small" type="primary" @click="getListData" plain
            >刷新列表
          </el-button>
        </div>
      </div>
      <div class="table">
        <el-table
          border
          v-loading="loading"
          :header-cell-style="{
            background: '#fafafa',
            color: '#333333',
            padding: '4px 0'
          }"
          :cell-style="{ padding: '4px 0' }"
          :data="tableData"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          @sort-change="handleSortChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column
            property="product.title"
            label="产品名称"
            min-width="200"
          />
          <el-table-column property="fullname" label="称呼" width="80" />
          <el-table-column property="phone" label="联系电话" width="120" />
          <el-table-column property="money" label="预约金额" width="100">
            <template #default="scope"> {{ scope.row.money }}万 </template>
          </el-table-column>
          <el-table-column
            property="createTime"
            label="预约提交时间"
            width="160"
            align="center"
          />
          <el-table-column
            property="updateTime"
            label="预约处理时间"
            width="160"
            align="center"
          >
            <template #default="scope">
              <el-tag type="danger" v-if="scope.row.status == 0">未处理</el-tag>
              <span v-else>{{ scope.row.updateTime }}</span>
            </template>
          </el-table-column>
          <el-table-column property="remark" label="备注" />
          <el-table-column fixed="right" label="操作" width="80">
            <template #default="scope">
              <el-button
                type="text"
                size="small"
                @click="showHandleClick(scope.row)"
                v-if="scope.row.status == 0"
                v-permission="['sys:userReserve:update']"
                >处理预约
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align: center;padding-top: 20px;">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="queryForm.limit"
          :current-page="queryForm.page"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </el-card>

    <handle-reserve
      v-if="showHandleDialog"
      v-model="showHandleDialog"
      :id="selectedItemId"
      @close="getListData"
    ></handle-reserve>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { getUserReservePage } from '@/api/user-reserve'
import { getUserById } from '@/api/user'
import HandleReserve from './components/HandleReserve'
import { useRoute } from 'vue-router'

const route = useRoute()

const queryForm = ref({
  page: 1,
  limit: 10,
  sort: 'createTime',
  order: 'desc',
  userId: route.params.userId,
  fullname: '',
  phone: '',
  status: ''
})

/**
 * 获取用户基本信息
 */
const userInfo = ref(null)
const getUserInfo = async userId => {
  userInfo.value = await getUserById(userId)
}
if (queryForm.value.userId) {
  getUserInfo(queryForm.value.userId)
}

/**
 * 表格数据相关
 */

const loading = ref(false)
const tableData = ref([])
const total = ref(0)
// 获取数据的方法
const getListData = async () => {
  loading.value = true
  const result = await getUserReservePage(queryForm.value)
  tableData.value = result.list
  total.value = result.total
  loading.value = false
}
getListData()

/**
 * 分页数量改变事件
 */
const handleSizeChange = val => {
  queryForm.value.limit = val
  getListData()
}
const handleCurrentChange = val => {
  queryForm.value.page = val
  getListData()
}
/**
 * 选中的列表id数组
 */
const selectItemIds = ref([])
const handleSelectionChange = val => {
  selectItemIds.value = []
  val.forEach(row => {
    selectItemIds.value.push(row.roleId)
  })
}

/**
 * 要操作的项目id
 */
const selectedItemId = ref(0)

/**
 * 显示预约处理对话框
 */
const showHandleDialog = ref(false)
const showHandleClick = row => {
  selectedItemId.value = row.id
  showHandleDialog.value = true
}
</script>

<style scoped></style>
